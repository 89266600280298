import React from 'react'
import SearchPage from '../components/Pages/CategoryTemplate/SearchPage/SearchPage'
import Layout from "../components/layout"

export default () => {
    return(
        <Layout>
            <SearchPage />
        </Layout>
    )
}