import React from "react";
import Header from '../Header/Header';
import Templates from "../Templates/Templates";
import * as styles from "./searchpage.module.scss";

export default () => {
  const [query, setQuery] = React.useState(null);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get("query");
    setQuery(query);
  }, []);

  return (
    <div className={styles.container}>
        <Header title={`Results for ${query}`}/>
      {query ? <Templates query={query} /> : null}
    </div>
  );
};
